<template>
    <div :class="deviceType == 'pc'?'service':'service-m'">
        <div class="banner flex-column">
            <h1>产品服务</h1>
            <h3>互联网行业数字化专家</h3>
        </div>
        <div class="module1 flex-column">
            <h1>技术服务</h1>
            <div class="module1-list flex-row">
                <div v-for="(item,index) in moduleList1" :key="'module1' + index" class="module1-item" :style="{border:'2px solid '+item.color}">
                    <div class="flex-column" :style="{background:item.color}">{{item.title}}</div>
                    <p>{{item.content}}</p>
                </div>
            </div>
        </div>
        <div class="module1 flex-column">
            <h1>软件实施</h1>
            <div class="module1-list flex-row">
                <div v-for="(item,index) in moduleList2" :key="'module2' + index" class="module2-item">
                    <img :src="item.icon" alt="">
                    <h3>{{item.title}}</h3>
                    <p>{{item.content}}</p>
                </div>
            </div>
        </div>
        <div class="module1 flex-column">
            <h1>运营服务</h1>
            <div class="module1-list flex-row">
                <div class="operate flex-column">
                    <h3 style="text-align: end;">大数据分析</h3>
                    <h3>本地化服务</h3>
                    <h3 style="text-align: end;">电商业务运营</h3>
                </div>
                <img class="module3-img" src="../assets/service09.png" alt="">
                <div class="operate flex-column">
                    <h3>用户交互设计</h3>
                    <h3 style="text-align: end;">线上品牌营销</h3>
                    <h3>内容管理解决方案</h3>
                </div>
            </div>
        </div>
        <div class="module1 flex-column">
            <h1>我们的服务保障</h1>
            <div class="module1-list flex-row">
                <div v-for="(item,index) in moduleList3" :key="'module3' + index" class="module3-item">
                    <img :src="item.icon" alt="">
                    <h3>{{item.title}}</h3>
                    <p>{{item.content}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            moduleList1: [
                { title: "运维服务", content: "基于企业复杂的计算机硬件、网络及服务器问题，社维思致力于全行业IT解决方案。通过专业的运营体系和团队搭建、加速中小型企业发展进程。", color: '#1183EE' },
                { title: "数字化产品+平台研发", content: "社维思打造了数字化服务系统快速定制化开发PaaS云平台，通过最大限度地技术复用，实现低成本定制化软件开发，使应用开发者可以快速、便捷地为客户构建功能强大、业务个性化的跨界数字化应用系统。", color: '#04BBF7' },
                { title: "物联网应用", content: "一切从连接开始，为各行业智能设备应用提供专业物联网解决方案，实现数据、场景、技术的协同管理。", color: '#08B72E' },
                { title: "大数据平台", content: "社维思的数据实施方案，以大数据hadoop为技术平台构建起数据分析生态体系，为企业搭建了从基础平台应用—数据质量管控-企业数据运营这一整套以数据支撑企业的运营和管理体系。", color: '#F9BF8A' }
            ],
            moduleList2: [
                {
                    icon: require('../assets/service01.png'),
                    title: "小程序开发",
                    content: "为您定制精细化的小程序，专注每一个细节设计。"
                },
                {
                    icon: require('../assets/service03.png'),
                    title: "H5&网页开发",
                    content: "h5端、web端全案策划开发。为您量身定制h5营销方案等。"
                },
                {
                    icon: require('../assets/service04.png'),
                    title: "APP应用开发",
                    content: "为您提供个性化APP高端定制化的服务。针对ios、Android、pad、智能物联等主流系统开发。"
                },
                {
                    icon: require('../assets/service02.png'),
                    title: "应用系统开发",
                    content: "拥有行业最新解决方案，提升企业运营效率。针对客户需求提供ERP、CRM、业务系统、大数据应用系统等定制解决方案。"
                },
            ],
            moduleList3: [
                {
                    icon: require('../assets/service10.png'),
                    title: "高可用代码研发",
                    content: "专业Apihub管理工具，通过前后分离规范化代码实施，降低开发成本，超越竞争对手快人一步"
                },
                {
                    icon: require('../assets/service11.png'),
                    title: "SCRUM精细项目管理",
                    content: "29项节点监控，32项交付物文档，10年+经验项目经理一对一服务，精细化持续管控"
                },
                {
                    icon: require('../assets/service12.png'),
                    title: "全行业领域产品智库",
                    content: "30万全行业领域产品经理精准匹配，快速验证需求有效性"
                },
                {
                    icon: require('../assets/service13.png'),
                    title: "应用程序业务网络",
                    content: "基于各类优质已验证产品应用方案，与业务深度结合，搭建专属应用程序业务网络"
                }
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.service {
    padding: 80px 0;
    .banner {
        width: 100vw;
        min-width: 600px;
        height: 400px;
        position: relative;
        overflow: hidden;
        background: url('../assets/service_banner.jpg') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: center;
        h1 {
            font-size: 48px;
            margin-bottom: 24px;
        }
        h3 {
            font-size: 36px;
        }
    }
    .module1 {
        margin: 120px 0;
        h1 {
            font-size: 42px;
            margin-bottom: 40px;
            font-weight: bolder;
        }
        .module1-list {
            .module1-item {
                width: 300px;
                height: 400px;
                margin: 0 16px;
                border: 1px solid #cccccc;
                div {
                    color: #ffffff;
                    height: 120px;
                    font-size: 28px;
                }
                p {
                    padding: 16px;
                    text-align: start;
                    font-size: 20px;
                }
            }
            .module2-item {
                width: 300px;
                height: 400px;
                margin: 0 16px;
                box-shadow: 4px 4px 6px #cccccc;
                overflow: hidden;
                img {
                    width: 100px;
                    height: 100px;
                    margin: 32px 0;
                }
                h3 {
                    font-size: 24px;
                    margin: 16px 0;
                }
                p {
                    padding: 16px;
                }
            }
            .module3-item {
                width: 300px;
                height: 400px;
                margin: 0 16px;
                background: #FAFAFA;
                overflow: hidden;
                img {
                    width: 100px;
                    height: 100px;
                    margin: 32px 0;
                }
                h3 {
                    font-size: 24px;
                    margin: 16px 0;
                }
                p {
                    padding: 16px;
                }
            }

            .operate {
                width: 300px;
                height: 400px;
                h3 {
                    font-size: 32px;
                    width: 100%;
                }
            }
        }
    }
}
.service-m {
    width: 100%;
    padding: 60px 0;
    h1 {
        font-size: 24px;
        margin: 20px 0;
        font-weight: bolder;
    }
    h3 {
        font-size: 18px;
        margin: 8px 0;
    }
    p {
        font-size: 14px;
        text-align: start;
        text-indent: 28px;
    }
    .banner {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        background: url('../assets/service_banner.jpg') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: center;
    }
    .module1 {
        .module1-list {
            flex-wrap: wrap;
            .module1-item {
                width: 46%;
                height: 360px;
                margin: 0 0 16px 0;
                border: 1px solid #cccccc;
                div {
                    color: #ffffff;
                    height: 120px;
                    font-size: 18px;
                }
                p {
                    padding: 16px;
                }
            }
            .module2-item {
                width: 46%;
                height: 360px;
                margin: 0 0 16px 0;
                box-shadow: 4px 4px 6px #cccccc;
                overflow: hidden;
                img {
                    width: 100px;
                    height: 100px;
                    margin: 32px 0;
                }
                h3 {
                    margin: 16px 0;
                }
                p {
                    padding: 16px;
                }
            }
            .module3-img {
                width: 46%;
                // height: 200px;
            }
            .operate {
                width: 100px;
                height: 200px;
                h3 {
                    width: 100%;
                    font-size: 14px;
                }
            }
            .module3-item {
                width: 46%;
                height: 320px;
                margin: 0 0 16px 0;
                background: #FAFAFA;
                overflow: hidden;
                img {
                    width: 100px;
                    height: 100px;
                    margin: 16px 0;
                }
                h3 {
                    margin: 16px 0;
                }
                p {
                    padding: 16px;
                }
            }
        }
    }
}
</style>